import React from 'react';
import { get } from 'lodash';
import axios from 'axios';
import { navigate } from '@reach/router';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'q3-ui-locale';
import Alert from '@material-ui/lab/Alert';
import { isReadyForCreditCardProcessing } from 'gatsby-theme-gentek/src/components/helpers';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import Container from '@material-ui/core/Container';
import Pay from '../components/Pay';
import TransactionLayout from '../components/TransactionLayout';
import ConfirmationSvg from '../assets/payment.svg';
import { getCreditCardBrand } from '../utils';
import PayNote from '../components/PayNote';

const {
  ORDERS: { ENDPOINT },
} = COLLECTIONS;

const payForOrderById = (id) => (token) =>
  axios
    .post(`${ENDPOINT}/${id}/pay`, token)
    .then(() => navigate(`/account/orders/${id}`));

const PayForm = ({
  id,
  paymentOption,
  total,
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <TransactionLayout imgSrc={ConfirmationSvg} title="pay">
      <Typography>
        {t('descriptions:payForOrder')}
      </Typography>
      <PayNote
        total={total}
        currency={currency}
        paymentOption={paymentOption}
      />
      <Box mt={1}>
        <Pay
          service={(token) =>
            payForOrderById(id)(token).then(() => {
              window.location.replace('/order-thanks');
            })
          }
          options={{
            'card-number': {
              brands: getCreditCardBrand(paymentOption),
            },
          }}
        />
      </Box>
    </TransactionLayout>
  );
};

const PayGuest = (props) => {
  const { t } = useTranslation();
  const ordersId = new URLSearchParams(
    get(props, 'location.search'),
  ).get('ordersId');

  const [fetching, setFetching] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [err, setError] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(`/orders-guest?ordersID=${ordersId}`)
      .then(({ data: { order } }) => {
        if (!isReadyForCreditCardProcessing(order))
          throw new Error(
            'Not ready for credit processing',
          );

        setData(order);
        setFetching(false);
      })
      .catch(() => {
        setError(true);
        setFetching(false);
      });
  }, [ordersId]);

  const renderer = () => {
    if (fetching) return t('labels:fetching');
    if (err)
      return (
        <Alert severity="error">
          {t('descriptions:cannotPayForOrder')}
        </Alert>
      );

    return <PayForm {...data} />;
  };

  return (
    <Box py={4}>
      <Container>{renderer()}</Container>
    </Box>
  );
};

export default PayGuest;
